@font-face {
            font-family: 'Raleway';
            src: url('fonts/Raleway-Regular.ttf');
}
body {
  font-family: Raleway, Arial, sans-serif;
  width:auto; height:auto;
  margin: 0;
  color: #6a6a6a;
  background: #f1f0f0;
  font-weight: normal;
}

.image-box {

  box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.5);
  background: transparent url('images/wood3.jpg') no-repeat center center fixed ;
  background-size: cover;

  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.5);
  transition: box-shadow .3s ease-out;
}

.image-box:hover {
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.3);
    }

.image-box2{
  box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.5);
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) , url('images/greywood.jpg') center center;
  background-size: cover;

  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: box-shadow .3s ease-out;
}

.image-box2:hover {
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.2);
    }

.image-box3{
    background: transparent url('images/wood2.jpg') no-repeat center center fixed;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    overflow: hidden;
    width: 100%;
    margin: 0;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.5);
    transition: box-shadow .3s ease-out;
}

.image-box3:hover {
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.2);
    }

.home .container{
  height: 100vh;
  background-color: #f1f0f0;
}

.align-items-center {
  display: flex; 
  align-items: center;  /*Aligns vertically center */
  justify-content: center; /*Aligns horizontally center */
}

.section {
    background: transparent url('images/greywood.jpg') no-repeat center center fixed;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    overflow: hidden;
    width: 100%;
    margin: 0;
    height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.5);
    transition: box-shadow .3s ease-out;
  }

  .section:hover {
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0);
    }

  .skill-desc-row{
    height:80%
  }

  button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: #484a4d !important;
    color: white !important;
    text-decoration-color: white;
    font-size: 0.8rem !important;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover,
button:focus {
    background: #242526 !important;
}

button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

.reducepadding{
  padding: 0px !important;
  margin: 0px !important;
}

.increasepadding{
  padding: 5px !important;
  margin: 5px !important;
}

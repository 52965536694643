:root{
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

@font-face {
  font-family: 'Raleway' !important;
  src: url('fonts/Raleway-Regular.ttf');
}

ul{
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.navbar a{
  color: var(--text-color) !important;
  text-decoration: none !important;
}

.nav-item{
  width: calc(var(--nav-size) * 2.0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar{
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1 rem;
  border-bottom: var(--border);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  transition: top 0.6s;
  top: 0;
  left: 0;
}

.navbar--hidden{
  top: -50px;
}

.navbar-nav{
  max-width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.item-button{
  --button-size: calc(var(--nav-size) * 1);
  width: var(--button-size * 1.5);
  height: var(--button-size * 0.5);
  padding: 3px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tradeChecker {
  background: white !important;
  text-align: center;
  background-color: white !important;
}

.tradeChecker .body{
  background: white !important;
  text-align: center;
  background-color: white !important;
}

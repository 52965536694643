.overrides body{
  background-color: white;
}

.overrides .header{
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.overrides .player{
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items:center;
    margin: auto;
  width: 130px;
  height: 40px;
  text-align: center;
  }

.button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;

  text-align: center;
  border-radius:40px;
  color:white;
  letter-spacing:1px;
}

.tradeChecker body{
  background: white
}
